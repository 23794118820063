<template>
  <div>
    <div style="margin-top: 100px; border: 1px solid #000;">
      <van-field v-model="value" label="祝福文本" rows="12" type="textarea" placeholder="请输入祝福语,用英文逗号隔开" />
    </div>
    <div @click="submit" class="btn">
      提交
    </div>
  </div>
</template>

<script>
import { getEmojiArray } from '@/lib/emoji';
export default {
  data() {
    return {
      value: ''
    }
  },
  methods: {
    submit() {
      let v = this.value.split(',')
      v = v.filter(item => item != '')
      if (v.length == 0) {
        this.$toast('请输入祝福语')
        return
      }
      for (let i = 0; i < v.length; i++) {
        let arr = getEmojiArray(v[i])
        if (arr.length > 20) {
          this.$toast('祝福语长度不能超过20')
          return
        }
      }
      this.$axios.post(`${this.baseURLApp}/anniversary/sixth/preheat/addActualWishes`, v).then((res) => {
        this.$toast('提交成功')
      })
    }
  }
}
</script>

<style lang="less" scoped>
.btn {
  margin-top: 20px;
  border: 1px solid #000;
  padding: 10px;
  text-align: center;
  display: inline-block;
}
</style>